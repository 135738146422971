.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background-image: url("../img/logooo-50px.png");
  background-size: cover;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.doctorPatientSearchTable,
.parentPatientsTable thead > tr > th {
  font-weight: 700;
}

/* tbody > tr > td {
  background-color: rgb(240, 245, 255);
} */

.ant-card-meta-title {
  white-space: pre-line;
}

/* .ant-card-actions {
  display: inline;
  vertical-align: bottom;
} */

.reqsListItems .ant-list-item:hover {
  box-shadow: 0.3vh 0.3vh 0.3vh 0.3vh rgba(208, 216, 243, 0.6);
}

.diffirentDoctor {
  background: #e6f7ff;
}

.AppDownloadButton {
  background: #d4380d;
  border-color: #d4380d;
}

.AppDownloadButton:hover {
  background: #ff7a45;
  border-color: #d4380d;
}